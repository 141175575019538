import InputHeader from '@/components/InputHeader.vue';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useGetSystemNotificationsQuery, EventNotificationType, EventScope, UserRoleState, useGetUserNotificationsQuery, useUpdateUserNotificationsPreferencesMutation } from '@/generated-types/graphql.types';
import { cloneDeep, isEqual } from 'lodash';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
    components: {
        InputHeader,
        FormCheckbox,
        SaveFooter
    },
    setup(_, context) {
        const { root } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const form = ref({
            events: {}
        });
        const notificationsBackup = ref({
            events: {}
        });
        const isFormUpdated = ref(false);
        const { result: allSystemNotifications } = useGetSystemNotificationsQuery({ fetchPolicy: 'cache-and-network' });
        const availableNotifications = computed(() => {
            return ((user.value &&
                allSystemNotifications?.value?.get_system_notifications.filter((notification) => {
                    return user.value.account_state === UserRoleState.Booker
                        ? notification.is_booker
                        : notification.is_vendor;
                })) ||
                []);
        });
        const adminNotifications = computed(() => availableNotifications.value?.filter((notification) => notification.event_scope === EventScope.Admin) || []);
        const authNotifications = computed(() => availableNotifications.value?.filter((notification) => notification.event_scope === EventScope.Authentication) || []);
        const bookingNotifications = computed(() => availableNotifications.value?.filter((notification) => notification.event_scope === EventScope.Booking) || []);
        const locationNotifications = computed(() => availableNotifications.value?.filter((notification) => notification.event_scope === EventScope.Location) || []);
        watch(() => availableNotifications.value, () => {
            const events = availableNotifications.value?.map((notification) => {
                return {
                    event_id: notification.event_id,
                    is_enabled: true
                };
            }) || [];
            form.value = {
                events: events.reduce((acc, notification) => {
                    acc[notification.event_id] = notification.is_enabled;
                    return acc;
                }, {})
            };
        }, { immediate: true });
        const { result: allUserNotifications, refetch: refetchUserNotifications } = useGetUserNotificationsQuery({ fetchPolicy: 'no-cache' });
        const userNotifications = computed(() => {
            return allUserNotifications.value?.get_all_user_system_notifications.map((notification) => {
                return {
                    event_id: notification.event_id,
                    is_enabled: notification.is_enabled
                };
            });
        });
        const setUserNotificationsToForm = () => {
            userNotifications.value?.forEach((notification) => {
                if (form.value.events[notification.event_id] !== undefined) {
                    form.value.events[notification.event_id] = notification.is_enabled;
                }
            });
            notificationsBackup.value.events = cloneDeep(form.value.events);
        };
        watch(() => [userNotifications.value, form.value.events], () => {
            if (userNotifications.value && Object.keys(form.value.events).length) {
                setUserNotificationsToForm();
            }
        }, { immediate: true });
        const hasChanges = () => {
            isFormUpdated.value = false;
            return !isEqual(form.value, notificationsBackup.value);
        };
        const onRevertChanges = () => {
            setUserNotificationsToForm();
        };
        const { mutate } = useUpdateUserNotificationsPreferencesMutation();
        const toast = useToast();
        const onSaveChanges = () => {
            const input = Object.keys(form.value.events).map((key) => {
                const _key = Number(key);
                return {
                    event_id: _key,
                    is_enabled: form.value.events[_key]
                };
            });
            mutate({ input })
                .then(() => {
                isFormUpdated.value = true;
                refetchUserNotifications();
            })
                .catch(() => {
                onClose();
                toast.error(root.$i18n.t('common.errors.something_wrong'));
            });
        };
        const onClose = () => {
            isFormUpdated.value = false;
        };
        const isChangesSaved = () => {
            return isFormUpdated.value;
        };
        return {
            adminNotifications,
            authNotifications,
            bookingNotifications,
            form,
            locationNotifications,
            EventScope,
            EventNotificationType,
            hasChanges,
            isChangesSaved,
            onClose,
            onRevertChanges,
            onSaveChanges
        };
    }
});
