import { computed, defineComponent } from '@vue/composition-api';
import Toggle, { Option } from '@/uikit/Toggle.vue';
import { AccountState, useUpdateUserStateMutation } from '@/generated-types/graphql.types';
import { withAuthMutationHook } from '@/util/hooks';
import { useToast } from 'vue-toastification/composition';
const updateProfile = withAuthMutationHook(useUpdateUserStateMutation);
export default defineComponent({
    components: {
        Toggle,
        Option
    },
    setup(_, context) {
        const { root, emit } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const { mutate } = updateProfile({ clientId: 'legacy' });
        const toast = useToast();
        const changeRole = async (value) => {
            if (user.value.account_state !== value) {
                await mutate({ profile: { accountState: value } });
                emit('change', value);
                const isBooker = value === AccountState.Booker;
                globalThis.$store.dispatch('$_app/refetchUser');
                const toastMessage = root.$i18n.t('profile.account_modal.toast_logged_in', {
                    role: isBooker ? 'Booker' : 'Vendor'
                });
                toast.info(toastMessage);
            }
        };
        return {
            user,
            AccountState,
            changeRole
        };
    }
});
